import React from 'react'
import { Container, LinearProgress, Typography } from '@material-ui/core'
import { SignupClient } from '../../../utils/signupClient'
import useFunnelConfirmationParams from './_utils'
import { useMutation } from 'react-query'
import Layout from '../../../components/layout'
import { gtagEvent } from '../../../utils/analytics'

const client = new SignupClient()
const FakeLinearProgress = props => {
  const [value, setValue] = React.useState(1)
  React.useEffect(() => {
    const update = () => setValue(Math.pow(Math.pow(value, 19) * 100, 0.05))
    const id = setInterval(update, 150 + 2 * value)
    return () => clearInterval(id)
  }, [value])
  return (
    <>
      <LinearProgress color="secondary" variant="determinate" value={value} />
    </>
  )
}
interface Props {
  location: Location
}
const LaunchBody = (props: Props) => {
  const confirmationData = useFunnelConfirmationParams(props.location)
  const [
    launch,
    launchStatus,
  ] = useMutation((data: { funnelId: string; code: string }) =>
    client.launchWS(data)
  )
  React.useEffect(() => {
    if (
      confirmationData.workspace !== undefined &&
      confirmationData.funnel !== undefined &&
      confirmationData.key !== undefined
    ) {
      gtagEvent('4_initiated_signup_launch')
      launch({ funnelId: confirmationData.funnel, code: confirmationData.key })
      //We got it back from the server
    }
  }, [confirmationData.workspace])
  React.useEffect(() => {
    if (launchStatus.isSuccess) {
      gtagEvent('5_launch_success')
      window.location.replace(launchStatus.data.redirectURL)
    }
  }, [launchStatus.isSuccess])

  if (launchStatus.isError) {
    return (
      <Typography>
        {' '}
        Oh no. Something went wrong. Please contact support
      </Typography>
    )
  }
  if (launchStatus.isLoading) {
    return (
      <>
        <Typography>
          {`We're setting up your new LightTag workspace at ${confirmationData.workspace}.lighttag.io. This can take a minute`}
        </Typography>
        <FakeLinearProgress />
      </>
    )
  }

  if (launchStatus.isSuccess) {
    ;<Typography>
      {`We're redirecting you to ${confirmationData.workspace}.lighttag.io`}
    </Typography>
  }
  return (
    <Typography color={'error'}>
      Well this is odd. Please contact support
    </Typography>
  )
}

const Launch: React.FunctionComponent<Props> = props => (
  <Layout
    pageTitle={'Launching LightTag'}
    pageDescription={'Setting Up The LightTag Workspace'}
  >
    <Container>
      <Typography
        style={{ marginTop: '140px', marginBottom: '40px' }}
        variant={'h3'}
      >
        We're launching LightTag for you
      </Typography>
      <LaunchBody location={props.location} />
    </Container>
  </Layout>
)

export default Launch
